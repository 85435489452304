import { Link } from "@remix-run/react";
import { Button } from "../ui/button";
import { PageHeader } from "./page-header";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";
import { AnimatePresence, motion } from "framer-motion";
import { useMemo, useState } from "react";
import { AnimateHeight } from "../atoms/animate-height";

const hotels = [
  {
    name: "Thermal Šírava Hotel",
    distance: 2.5,
    url: "https://www.thermalsirava.sk/ubytovanie/thermal-sirava-hotel",
  },
  {
    name: "Residence Šírava SPA RESORT",
    distance: 3.6,
    url: "https://residencesirava.sk",
  },
  { name: "Hotel Eurobus", distance: 3.5, url: "https://www.hoteleurobus.sk" },
  {
    name: "Wellness Hotel Chemes",
    distance: 3.8,
    url: "http://www.hotelchemes.sk",
  },
  {
    name: "Hotel Energetik",
    distance: 4.0,
    url: "https://www.energetiksirava.sk",
  },
  { name: "Penzión Juliana", distance: 3.7, url: "https://julianasirava.sk" },
  { name: "Hotel Glamour", distance: 3.6, url: "https://hotelglamour.sk" },
  { name: "Hotel Vinnay", distance: 3.8, url: "https://hotelvinnay.sk" },
  {
    name: "Penzión Stefanie",
    distance: 1.7,
    url: "https://www.stefanie.sk/home",
  },
  {
    name: "Hotel Družba - Michalovce",
    distance: 7.8,
    url: "https://www.hoteldruzbami.sk",
  },
  {
    name: "Hotel Zemplen - Michalovce",
    distance: 9.2,
    url: "https://hotelzemplen.sk",
  },
  {
    name: "Hotel Mousson - Michalovce",
    distance: 8.5,
    url: "https://www.hotelmousson.sk",
  },
  {
    name: "Penzión Lucus",
    distance: 4.0,
    url: "http://www.penzionlucus.sk",
  },
  {
    name: "Penzión Hedera",
    distance: 3.6,
    url: "https://penzionhedera.sk",
  },
  {
    name: "Hotel Merkúr",
    distance: 2.7,
    url: "https://hotelmerkur.sk",
  },
  {
    name: "Penzión Anima",
    distance: 2.5,
    url: "https://www.penzionanima.sk",
  },
  {
    name: "Penzión “Hotel Felícia“",
    distance: 3.5,
    url: "https://hotelfelicia.sk/uvod/",
  },
].sort((a, b) => a.distance - b.distance);

export const Hotels = () => {
  const [maxDistance, setMaxDistance] = useState<number | null>(null);

  const filteredHotels = useMemo(() => {
    if (!maxDistance) return hotels;

    return hotels.filter((hotel) => hotel.distance < maxDistance);
  }, [maxDistance]);

  return (
    <div id="ubytovanie" className="pt-16 pb-24 text-white bg-neutral-900">
      <PageHeader title="Ubytovanie" subtitle="Hotely a penzióny v okolí" />
      <div className="container flex items-center justify-center gap-4">
        <Button
          variant={maxDistance === 3 ? "default" : "ghost"}
          onClick={() => setMaxDistance(3)}
        >
          Do 3km
        </Button>
        <Button
          variant={maxDistance === 4 ? "default" : "ghost"}
          onClick={() => setMaxDistance(4)}
        >
          Do 4km
        </Button>
        <Button
          variant={maxDistance === null ? "default" : "ghost"}
          onClick={() => setMaxDistance(null)}
        >
          Všetky
        </Button>
      </div>
      <AnimateHeight>
        <div className="container flex flex-col flex-wrap justify-center w-full gap-4 pt-16 lg:gap-10 lg:flex-row">
          <AnimatePresence initial={false}>
            {filteredHotels.map(({ name, distance, url }) => (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                layoutId={`hotel-${name}`}
                key={`hotel-${name}`}
              >
                <Link
                  to={url}
                  target="_blank"
                  rel="noreferrer"
                  className="relative flex items-center justify-between gap-4 p-4 transition-colors rounded-lg lg:p-8 bg-neutral-800 hover:bg-neutral-700"
                >
                  <div className="text-lg font-medium">{name}</div>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="flex items-center justify-center text-sm font-bold text-black bg-white rounded-full lg:absolute w-14 aspect-square -top-6 -right-6">
                        {distance} km
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      {distance} km od Areálu Zrazu Motorkárov
                    </TooltipContent>
                  </Tooltip>
                </Link>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </AnimateHeight>
    </div>
  );
};
