import { motion } from "framer-motion";
import { ReactNode } from "react";

export type ShowOnScrollProps = {
  children?: ReactNode;
  delay?: number;
  className?: string;
};

export const ShowOnScroll = ({
  children,
  delay,
  className,
}: ShowOnScrollProps) => {
  return (
    <motion.div
      className={className}
      animate={{ y: 100, opacity: 0 }}
      whileInView={{
        y: 0,
        opacity: 1,
        transition: {
          type: "spring",
          bounce: 0.4,
          duration: 0.5,
          delay,
        },
      }}
      viewport={{ once: true, amount: 0.2 }}
    >
      {children}
    </motion.div>
  );
};
