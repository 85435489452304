import { useTranslation } from "react-i18next";
import { ShowOnScroll } from "../atoms/show-on-scroll";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { PageHeader } from "./page-header";
import { useForm } from "react-hook-form";
import { z } from "~/utils/translatedZod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "~/components/ui/form";
import { createApiClient } from "~/services/api";
import { useEffect, useState } from "react";

export const Newsletter = () => {
  const { t } = useTranslation();

  const formSchema = z.object({
    email: z.string().min(1).max(255).email(),
  });

  const [isSuccess, setSuccess] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: {
      email: "",
    },
    mode: "onSubmit",
    resolver: zodResolver(formSchema),
  });

  const api = createApiClient();

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const result = await api.subscribeToNewsletter(values.email);

    if (!result.isError) {
      setSuccess(true);
      return;
    }

    form.setError("email", { message: result.message });
  };

  useEffect(() => {
    const subscription = form.watch(() => setSuccess(false));
    return () => subscription.unsubscribe();
  }, [form, form.watch]);

  return (
    <ShowOnScroll>
      <div className="pb-16">
        <PageHeader
          title={t("components.blocks.newsletter.title")}
          variant="title"
        />
        <div className="container max-w-[840px]">
          <div className="flex justify-between bg-neutral-900 rounded-xl">
            <div className="flex flex-col gap-6 p-8 text-white">
              <div className="flex flex-col gap-2">
                <p className="text-white/50">
                  {t("components.blocks.newsletter.text")}
                </p>
              </div>
              <Form {...form}>
                <form
                  className="flex flex-col gap-4"
                  onSubmit={form.handleSubmit(onSubmit)}
                >
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            {...field}
                            placeholder={t(
                              "components.blocks.newsletter.enterEmailHere",
                            )}
                            className="max-w-96 text-foreground"
                          />
                        </FormControl>
                        <FormDescription />
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {isSuccess && <div>Diky</div>}
                  <Button type="submit" className="w-fit">
                    {t("components.blocks.newsletter.subscribe")}
                  </Button>
                </form>
              </Form>
            </div>
            <div className="hidden md:block">
              {/* <img
              className="-mt-20 w-80"
              src="https://vibefest.sk/img/vicaftr.png"
              alt=""
            /> */}
            </div>
          </div>
        </div>
      </div>
    </ShowOnScroll>
  );
};
