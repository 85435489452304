import { Button } from "../ui/button";
import { PageHeader } from "./page-header";
import {
  Music4Icon,
  PlugZapIcon,
  RefreshCcwIcon,
  WeightIcon,
} from "lucide-react";
import { LocaleLink } from "../atoms/locale-link";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import { useState } from "react";

const features = [
  {
    icon: <WeightIcon size={64} />,
    name: "Žiadna záťaž navyše",
    description:
      "Zbav sa zbytočnej batožiny. Cestuj naľahko a cíť sa príjemne!",
  },
  {
    icon: <RefreshCcwIcon size={64} />,
    name: "24h recepcia",
    description: "Náš tím je pripravený ti pomôcť 24 hodín denne.",
  },
  {
    icon: <Music4Icon size={64} />,
    name: "Relaxuj & užívaj",
    description:
      "Nenaťahuj sa so stavaním stanu, vybaľovaním, čistením a skladaním. Uži si tento čas s priateľmi.",
  },
  {
    icon: <PlugZapIcon size={64} />,
    name: "Zostaň na prijme",
    description: "Zostaň v spojení s kamošmi s extra dávku energie.",
  },
];

const pros = [
  {
    name: "Komfort",
    description:
      "Už nemusíte ťahať vlastné stany a zaoberať sa ich stavbou. Po príchode na zraz sa môžete ihneď ubytovať a začať si užívať atmosféru plnú zábavy a dobrodružstva medzi motorkármi.",
  },
  {
    name: "Pohodlie",
    description:
      "Stan je postavený profesionálmi, čo znamená, že je pevne zakotvený a pripravený na všetky poveternostné podmienky. Môžete očakávať väčšie pohodlie, než keby ste si stan postavili sami.",
  },
  {
    name: "Vylepšená kvalita spánku",
    description:
      "Naše stany kategórie fresh&black ponúkajú unikátnu vlastnosť tmavej vnútornej vrstvy, ktorá zabezpečuje, že si ráno môžete pospať dlhšie bez toho, aby vás rušilo ranajšie slnko. Vonkajšia strieborná vrstva odráža teplé slnečné lúče, vďaka čomu je vo vnútri stanu príjemnejšie chladnejšie.",
  },
  {
    name: "Ideálna poloha",
    description:
      "Naše stany sú umiestnené v strategických lokalitách v rámci campingu, kde máte k dispozícii všetky potrebné služby a zároveň ste v srdci diania zrazu motorkárov. ",
  },
  {
    name: "Bezpečnosť",
    description:
      "V areáli, kde sú stany umiestnené, je zabezpečená vyššia miera bezpečnosti, takže si môžete byť istí, že vaše veci sú v bezpečí aj keď ste práve na koncerte, prehliadke alebo len tak vychutnávate atmosféru zrazu.",
  },
  {
    name: "Flexibilita a jednoduchosť",
    description:
      "Prenájom stanu u nás znamená, že nemusíte riešiť žiadne ďalšie logistické problémy. Všetko je pripravené a čaká na vás, takže sa môžete sústrediť len na to, aby ste si užili každú minútu na tomto výnimočnom podujatí.",
  },
  {
    name: "Žiadne predchádzajúce prípravy",
    description:
      "Všetko, čo potrebujete urobiť, je zarezervovať si stan online a potom sa len tešiť na podujatie.",
  },
];

export const Camping = () => {
  const [isMoreInfoOpen, setMoreInfoOpen] = useState(false);
  return (
    <div className="pt-16 pb-24 text-white bg-neutral-900">
      <PageHeader title="Camping" subtitle="V areáli Zrazu Motorkárov" />

      <div className="container flex flex-col gap-8">
        <div className="flex flex-col items-center gap-8 lg:gap-32 lg:flex-row">
          <div className="flex-shrink-0 w-full lg:w-96 max-w-96 aspect-square">
            <img
              className="object-cover w-full h-full"
              src="/images/wanna-camp.png"
              alt=""
            />
          </div>
          <div className="flex flex-col flex-1 gap-8">
            <p className="lg:text-lg">
              Priprav sa na nezabudnuteľný zážitok na Zraze Motorkárov 2024 s
              našimi kompletne postavenými stanmi, ktoré sú pripravené už pri
              tvojom príchode! Prichystáme ti stan s karimatkami a prikrývkami .
              Na recepcii môžeš požiadať o Budíček a náš Camping servis ťa
              zobudí na koncert, ktorý nechceš zmeškať. Ponúkame ti možnosť
              prenajatia stanov v dvoch veľkostiach - pre dve alebo tri osoby, a
              tiež v dvoch kategóriách - classic a fresh&black. Spolu s
              campingom ti prinášame službu{" "}
              <span className="font-bold">CHARGE & GO </span>
              veľkokapacitné prenosné nabíjačky, ktoré vám dodajú potrebnú
              energiu kdekoľvek ste. Keď sa tvoj powerbank vybije, na našej
              recepcii ho vymeníš za plne nabitý. Náš tím ťa bude zásobovať
              energiou 24 hodín denne.
            </p>
            <div className="flex flex-col gap-4 lg:flex-row">
              <Button asChild size={"lg"} className="lg:w-fit">
                <LocaleLink route="camping">Chcem kempovať</LocaleLink>
              </Button>

              <Dialog open={isMoreInfoOpen} onOpenChange={setMoreInfoOpen}>
                <DialogTrigger asChild>
                  <Button size={"lg"} variant="secondary" className="lg:w-fit">
                    Prečo stan od nás?
                  </Button>
                </DialogTrigger>
                <DialogContent className="flex flex-col items-center gap-8 p-12 overflow-auto max-h-svh text-foreground">
                  <div className="text-4xl font-medium text-center">
                    Prečo si vybrať stan od nás?
                  </div>
                  <div className="grid lg:grid-cols-2 gap-x-16 gap-y-8">
                    {pros.map(({ name, description }, index) => (
                      <div
                        key={index}
                        className="relative flex flex-col items-center flex-1 gap-4 text-center"
                      >
                        <div className="text-xl font-medium">
                          {index + 1}. {name}
                        </div>
                        <p>{description}</p>
                      </div>
                    ))}
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-wrap justify-center w-full gap-4 lg:gap-10 lg:flex-row">
          {features.map(({ name, icon, description }, index) => (
            <div
              key={index}
              className="relative flex flex-col items-center flex-1 gap-8 p-4 text-center transition-colors rounded-lg lg:p-8 bg-neutral-800"
            >
              <div>{icon}</div>
              <div className="flex flex-col items-center gap-4">
                <div className="text-lg font-medium uppercase">{name}</div>
                <div className="text-sm max-w-48">{description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
