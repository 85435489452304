import { Link } from "@remix-run/react";
import { Button } from "../ui/button";
import { Logo } from "../atoms/logo";
import { useTranslation } from "react-i18next";
import { useRootLoaderData } from "~/root";

export type HeroProps = {
  subtitle: string;
  ticketsShopUrl: string;
};

export const Hero = ({ subtitle, ticketsShopUrl }: HeroProps) => {
  const { t } = useTranslation();
  const { apiBaseUrl } = useRootLoaderData();

  return (
    <div className="aspect-video grid items-stretch w-full min-h-[500px] max-h-[calc(100vh-80px)] bg-black/90 text-white relative">
      <video
        className="absolute flex object-cover w-full h-full"
        src={`${apiBaseUrl}/videos/hero.mp4`}
        controls={false}
        autoPlay={true}
        muted
        loop
        poster="/images/hero.png"
      ></video>

      <div className="relative z-10 flex flex-col justify-end h-full pt-16 pb-[15%] bg-gradient-to-t from-black to-transparent">
        <div className="container flex flex-col items-center gap-4 text-center">
          <Logo className="max-w-[600px]" />
          <p className="text-2xl uppercase drop-shadow-xl">{subtitle}</p>
          <Button size="lg" asChild>
            <Link target="_blank" to={ticketsShopUrl} rel="noreferrer">
              {t("components.blocks.hero.buyTickets")}
            </Link>
          </Button>
        </div>
      </div>
    </div>
  );
};
