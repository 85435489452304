import { Badge } from "../ui/badge";
import { ShowOnScroll } from "../atoms/show-on-scroll";
import { Link } from "@remix-run/react";
import { LocaleLink } from "../atoms/locale-link";

export const Featured = () => {
  return (
    <div className="container py-16 flex flex-wrap gap-8 justify-center max-w-[1280px]">
      <ShowOnScroll delay={0.15} className="w-full max-w-64 lg:max-w-96">
        <LocaleLink unstable_viewTransition route="camping">
          <FeaturedItem
            title="Camping v areáli"
            badge="Camping"
            imgSrc="/images/featured/camping.png"
          />
        </LocaleLink>
      </ShowOnScroll>
      <ShowOnScroll delay={0.3} className="w-full max-w-64 lg:max-w-96">
        <Link to="https://www.youtube.com/watch?v=BTtyDWY-QL4">
          <FeaturedItem
            title="Ako to vyzeralo minulý rok?"
            badge="After movie"
            imgSrc="/images/featured/after-movie.png"
          />
        </Link>
      </ShowOnScroll>
      <ShowOnScroll delay={0.45} className="w-full max-w-64 lg:max-w-96">
        <LocaleLink unstable_viewTransition route="shop">
          <FeaturedItem
            title="Merch"
            badge="Merch"
            imgSrc="/images/featured/merch.png"
          />
        </LocaleLink>
      </ShowOnScroll>
    </div>
  );
};

export type FeaturedItemProps = {
  title: string;
  badge: string;
  imgSrc: string;
};

const FeaturedItem = ({ title, badge, imgSrc }: FeaturedItemProps) => {
  return (
    <div className="relative w-full group aspect-[2/3] rounded-3xl overflow-hidden flex flex-col justify-end transition-transform hover:scale-105 focus:scale-105">
      <img
        className="absolute object-cover w-full h-full transition-transform scale-110 rotate-2 group-hover:scale-100 group-focus:scale-100 group-hover:rotate-0 group-focus:rotate-0"
        src={imgSrc}
        alt=""
      />
      <div className="relative z-10 flex flex-col gap-2 p-4 pt-12 text-white bg-gradient-to-t from-black to-transparent">
        <p className="text-xl pr-[30%]">{title}</p>
        <Badge className="w-fit">{badge}</Badge>
      </div>
    </div>
  );
};
