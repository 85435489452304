import { Faqs } from "~/components/faqs";
import { Hero } from "~/components/blocks/hero";
import { Featured } from "~/components/blocks/featured";
import { Newsletter } from "~/components/blocks/newsletter";
import { useRootLoaderData } from "~/root";
import { ApiClient, createApiClient } from "~/services/api";
import { json, useLoaderData } from "@remix-run/react";
import { Hotels } from "~/components/blocks/hotels";
import { Camping } from "~/components/blocks/camping";
import { Performing } from "~/components/blocks/performing";

const fetchData = async (api: ApiClient) => {
  const faqs = await api.getFaqs();

  return { faqs };
};

export const loader = async () => {
  const api = createApiClient();
  const data = await fetchData(api);
  return json(data);
};

export const clientLoader = async () => {
  const api = createApiClient();
  const data = await fetchData(api);
  return data;
};

export default function Route() {
  const { settings } = useRootLoaderData();
  const { faqs } = useLoaderData<typeof loader>();

  return (
    <>
      <Hero
        subtitle="15. - 18.8.2024"
        ticketsShopUrl={settings.tickets_shop_url}
      />
      <Featured />
      <Performing />
      <Camping />
      <Faqs faqs={faqs} />
      <Hotels />
      <Newsletter />
    </>
  );
}
