import { motion } from "framer-motion";
import { Fragment, useEffect, useState } from "react";
import { Faq } from "~/services/api";
import { PageHeader } from "./blocks/page-header";

export type FaqsProps = {
  faqs: Faq[];
};

export const Faqs = ({ faqs }: FaqsProps) => {
  const [selectedId, setSelectedId] = useState<null | number>(null);

  useEffect(() => {
    const handler = () => {
      if (selectedId) {
        setSelectedId(null);
      }
    };
    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, [selectedId]);

  return (
    <>
      <div className="container flex flex-col gap-8 pb-16">
        <PageHeader title="Faq" variant="title" />
        <div className="relative grid gap-4 md:gap-8 sm:grid-cols-2">
          {faqs.map((faq) => (
            <Fragment key={`faq-${faq.id}`}>
              {selectedId === faq.id ? (
                <div key={`faq-${faq.id}`}>
                  <div className="relative top-0 left-0 z-10 flex items-center justify-center w-full h-full pointer-events-none sm:absolute">
                    <motion.div
                      layoutId={`faq-${faq.id}`}
                      className="relative max-w-2xl p-4 bg-white border pointer-events-auto md:p-8 rounded-2xl"
                    >
                      <button
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                        type="button"
                        onClick={() => setSelectedId(null)}
                        className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="w-4 h-4 lucide lucide-x"
                        >
                          <path d="M18 6 6 18"></path>
                          <path d="m6 6 12 12"></path>
                        </svg>
                      </button>
                      <div className="flex flex-col gap-4">
                        <motion.div
                          layoutId={`faq-question-${faq.id}`}
                          className="text-lg font-medium w-fit"
                          dangerouslySetInnerHTML={{
                            __html: faq.question,
                          }}
                        ></motion.div>
                        <motion.div
                          layoutId={`faq-answer-${faq.id}`}
                          dangerouslySetInnerHTML={{
                            __html: faq.answer,
                          }}
                        ></motion.div>
                      </div>
                    </motion.div>
                  </div>
                </div>
              ) : (
                <motion.div
                  key={faq.id}
                  layoutId={`faq-${faq.id}`}
                  onClick={() => setSelectedId(faq.id)}
                  className="flex flex-col p-4 transition-shadow bg-white border md:p8 rounded-2xl hover:shadow-xl"
                >
                  <button className="flex flex-col text-left cursor-pointer">
                    <motion.div
                      layoutId={`faq-question-${faq.id}`}
                      className="text-lg font-medium"
                      dangerouslySetInnerHTML={{
                        __html: faq.question,
                      }}
                    ></motion.div>
                    <motion.div
                      layoutId={`faq-answer-${faq.id}`}
                      className="w-full opacity-0"
                    ></motion.div>
                  </button>
                </motion.div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};
