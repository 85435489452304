import { useEffect, useMemo, useState } from "react";
import { PageHeader } from "./page-header";
import { AnimatePresence, motion } from "framer-motion";
import { LocaleLink } from "../atoms/locale-link";
import { Intepret, interprets } from "~/data/interprets";
import { ArrowRightCircleIcon } from "lucide-react";

type InterpretProps = {
  interpret: Intepret;
  delay: number;
};

const Interpret = ({ interpret, delay }: InterpretProps) => {
  return (
    <motion.div
      transition={{
        duration: 1,
        delay,
      }}
      key={`interpret-${interpret.name}`}
      layoutId={`interpret-${interpret.name}`}
      className="relative flex items-end w-full h-full overflow-hidden"
    >
      <img
        className="absolute object-cover w-full h-full"
        src={interpret.imageUrl}
        alt={interpret.name}
      />
      <div className="absolute inset-0 bg-gradient-to-t from-neutral-900 to-transparent"></div>
      <div className="relative z-10 p-3 text-lg font-bold text-white lg:p-8 md:text-5xl lg:text-3xl xl:text-5xl">
        {interpret.name}
      </div>
    </motion.div>
  );
};

const ShowAllInterpretsButton = () => {
  return (
    <LocaleLink
      route="interprets"
      className="flex flex-col justify-end w-full h-full gap-2 p-3 text-white group lg:p-8 bg-primary"
    >
      <div className="flex flex-col gap-2 transition-transform group-hover:-translate-y-4">
        <ArrowRightCircleIcon size={48} strokeWidth={1} />
        <div className="font-bold text-left md:text-4xl">
          Zobraziť všetkých interprétov
        </div>
      </div>
    </LocaleLink>
  );
};

type MosaicProps = {
  interprets: Intepret[];
};

const Mosaic = ({ interprets }: MosaicProps) => {
  const [cursor, setCursor] = useState(0);

  useEffect(() => {
    setTimeout(() => setCursor(1), 1000);
    const interval = setInterval(() => {
      setCursor((cursor) => {
        if (cursor >= interprets.length - 1) {
          return 0;
        }
        return cursor + 1;
      });
    }, 9000);
    return () => clearInterval(interval);
  }, [interprets]);

  const currentInterprets = useMemo(() => {
    const slice = interprets.slice(
      cursor,
      cursor + 9 /* 9 interprets are displayed at the same time */,
    );
    let i = 0;
    while (
      slice.length !== 9 /* 9 interprets are displayed at the same time */
    ) {
      slice.push(interprets[i]);
      i++;
    }
    return slice;
  }, [cursor, interprets]);

  return (
    <div className="relative grid overflow-hidden lg:grid-cols-2 bg-neutral-900 rounded-3xl">
      {/* <div className="absolute z-50 w-1/2 right-full aspect-square">
        <Interpret interpret={currentInterprets[0]} delay={0} />
      </div>
      <div className="relative z-50 aspect-square">
        <Interpret interpret={currentInterprets[1]} delay={1} />
      </div> */}

      <div className="grid lg:grid-cols-2">
        <div className="relative grid grid-cols-2 lg:grid-cols-1">
          <div className="absolute bottom-0 w-1/2 lg:w-full left-full lg:left-auto lg:right-full aspect-square">
            <Interpret interpret={currentInterprets[0]} delay={0} />
          </div>
          <div className="w-full aspect-square">
            <Interpret interpret={currentInterprets[2]} delay={2} />
          </div>
          <div className="w-full aspect-square">
            <Interpret interpret={currentInterprets[1]} delay={1} />
          </div>
        </div>
        <div className="relative grid grid-cols-2 lg:grid-cols-1">
          <div className="w-full aspect-square">
            <Interpret interpret={currentInterprets[3]} delay={3} />
          </div>
          <div className="w-full aspect-square">
            <Interpret interpret={currentInterprets[4]} delay={4} />
          </div>
        </div>
      </div>

      <div className="grid lg:grid-cols-2">
        <div className="relative grid grid-cols-2 lg:grid-cols-1">
          <div className="w-full aspect-square">
            <Interpret interpret={currentInterprets[6]} delay={6} />
          </div>
          <div className="w-full aspect-square">
            <Interpret interpret={currentInterprets[5]} delay={5} />
          </div>
        </div>
        <div className="relative grid grid-cols-2 lg:grid-cols-1">
          <div className="absolute w-1/2 lg:w-full lg:left-full top-full lg:top-0 aspect-square">
            <Interpret interpret={currentInterprets[8]} delay={8} />
          </div>
          <div className="w-full aspect-square">
            <Interpret interpret={currentInterprets[7]} delay={7} />
          </div>
          <div className="w-full aspect-square">
            <ShowAllInterpretsButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export const Performing = () => {
  return (
    <div id="interpreti" className="pt-16 pb-24">
      <PageHeader
        title="Interpréti"
        subtitle="Ktorí ti spravia program na tri dni!"
        variant="title"
      />
      <div className="container">
        <AnimatePresence>
          <Mosaic interprets={interprets} />
        </AnimatePresence>
      </div>
    </div>
  );
};
